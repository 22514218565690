<template>
  <div class="s-layout" :style="colorObject">
    <div class="s-container">
      <Header
        :meta="meta"
        :page-title="moduleConfig.page_title"
        :page-image="moduleConfig.page_image"
        :page-kv-image="moduleConfig.page_kv_image"
        :header-section="moduleConfig.header_section"
      />
      <div>
        <div class="page">
          <div
            v-if="getModuleConfig('welcome_page.form_title')"
            class="page__title"
          >
            {{ getModuleConfig("welcome_page.form_titaadle") }}
          </div>
          <div
            v-if="getModuleConfig('welcome_page.form_desc')"
            class="page__desc"
          >
            {{ getModuleConfig("welcome_page.form_desc") }}
          </div>
          <div class="page__content">
            <div
              v-if="getModuleConfig('welcome_page.content')"
              v-html="getModuleConfig('welcome_page.content')"
            ></div>
            <div>
              {{ messages }}
            </div>
          </div>

          <div class="page__button s-space-y-4">
            <MemberButton
              :button-text="getModuleConfig('welcome_page.button_text')"
              :button-url="getModuleConfig('welcome_page.button_url')"
              :type="getModuleConfig('welcome_page.button_action')"
            >
            </MemberButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Page/Liff/Shared/HeaderV3";
import themeColor from "@/mixins/liff/themeColor";
import module from "@/mixins/liff/coupon/main";
import MemberButton from "@/components/Page/Liff/Shared/MemberButton.vue";
import couponMixin from "@/mixins/liff/coupon";
import { mapGetters } from "vuex";
import liff from "@line/liff";

export default {
  mixins: [themeColor, module, couponMixin],
  components: {
    Header,
    MemberButton,
  },
  computed: {
    ...mapGetters({
      liffId: "liffGeneral/liffId",
      thanksMessage: "projAbbottAnd/thanksMessage",
    }),
    messages() {
      return this.thanksMessage ?? '';
    },
  },
  data() {
    return {
      themeConfigPage: "coupon",
    };
  },
  async created() {
    await this.init();
    await this.sendLiffMessage();
  },
  mounted() {},
  methods: {
    async sendLiffMessage() {
      if (this.getModuleConfig("welcome_page.send_messages.enable")) {
        await liff
          .sendMessages([
            {
              type: "text",
              text: this.getModuleConfig("welcome_page.send_messages.text"),
            },
          ])
          .then(() => {
            console.log("message sent");
          })
          .catch((err) => {
            console.log("error", err);
          });
        return;
      }
    },
    async init() {
      await liff
        .init({
          liffId: this.liffId,
        })
        .then(() => {
          if (!liff.isLoggedIn()) {
            liff.login({
              redirectUri: window.location.href,
            });
          }
        })
        .catch(() => {
          this.liffFailed();
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../assets/css/liff/main.css";
@import "../../../../assets/css/liff/liff_init_theme_config.css";

.s-layout,
.s-layout *,
.s-layout *:before,
.s-layout *:after {
  --s-secondary: #de006f;
  --s-warning: #ed6c00;
  --s-danger: #fe0000;
  --s-gray-darker: #363636;
  --s-gray-dark: #2c2c2e;
  --s-gray: #979797 !important;
  --s-gray-light: #c0c4cc;
  --s-gray-lighter: #f2f2f7;
}

.s-layout {
  color: var(--s-gray-dark);
  background-color: var(--liff-layout_bg_color);
  min-height: 100vh;
}

.s-container {
  padding-bottom: 1.5rem;
}

.content {
  padding: 16px 12px;
  background: var(--liff-content_bg_color);
}

::v-deep .page {
  padding: 16px 12px;
  background: var(--liff-content_bg_color);
  &__title {
    font-size: 20px;
    line-height: 28px;
    font-weight: 500;
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
  }
  &__desc {
    font-size: 14px;
    line-height: 22px;
    color: var(--liff-secondary_text_color);
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
    b {
      color: var(--s-primary);
      font-weight: 600;
    }
  }
  &__content {
    margin: 10px 20px;
  }
}
</style>
