<template>
<div class="page">
    <b-card class="mb-2">
        <b-spinner class="align-middle"></b-spinner>
        <div class="text-center">
            處理中...
        </div>
    </b-card>
</div>
</template>
<script>
import abbottAndMixin from "@/mixins/liff/proj/abbott-and";

export default {
  mixins: [abbottAndMixin],
  components: {},
  async mounted() {
    await this.init();
  },
  data() {
    return {
      lineData: {},
    };
  },
  computed: {},
  methods: {
    async init() {
      await this.submit()
    },
  }
}
</script>

<style lang="scss"></style>
